@import url('./assets/css/minireset.scss');
@import url('./assets/css/flex.scss');

// 页面 card 组件下的最小高度

.page-row-full,
.page-card-full {
    min-height: calc(100vh - 64px - 32px );
    margin: 16px;
    --diff: 0px;

    .row-card-full {
        min-height: 100%;
    }

    .ant-card-body {
        padding: 16px;

        .ant-table {
            min-height: calc(100vh - 64px - 32px  - 57px - 32px - 64px - var(--diff));
        }
    }

    .page-card-tabs-full {
        min-height: calc(100vh - 64px - 32px  - 57px - 32px - 64px);

        .ant-table {
            min-height: calc(100vh - 64px - 32px - 57px - 32px - 64px - 57px);
        }
    }
}


// 配置下拉选择图标选择框列表
.custom {
    .rc-virtual-list-holder-inner {
        flex-direction: row !important;
        flex-wrap: wrap;

        .ant-select-item {
            width: 100%;
        }

        .ant-select-item.ant-select-item-option-grouped {
            width: 10%;
            padding: 5px;
            justify-content: center;
            align-items: center;
            text-align: center;

            .ant-select-item-option-content {
                min-width: 10% !important;

                .anticon {
                    font-size: 22px !important;
                    color: #434343;
                }
            }
        }
    }
}

// 根据主题 配置通知提醒框颜色
.notification-light {
    background-color: #FFF;
    color: rgba(0, 0, 0, 0.88);
}

.notification-dark {
    background-color: #1F1F1F;
    color: rgba(255, 255, 255, 0.85);

    .ant-notification-notice-message {
        color: rgba(255, 255, 255, 0.85);
    }

    .ant-notification-notice-close {
        color: rgba(255, 255, 255, 0.85);
    }
}

// 根据主题 配置滚动条样式
.content-dark {
    ::-webkit-scrollbar {
        background-color: #1d1d1d;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(128, 128, 128, .5);
    }

    .row-table-full {
        background-color: rgb(36, 36, 36);
    }

    .page-card-search {
        background-color: rgb(29, 29, 29);
    }
}

// 列设置 => tree 样式
.dropdown-columns-tree {
    .ant-btn {
        width: auto;
        height: auto;
        padding: 0 4px;
        font-size: 12px;
    }

    .ant-tree-treenode {
        padding: 2px 0 4px;
    }

    .ant-tree-switcher-noop {
        display: none;
    }
}

// 配置tree行高
.custom {
    &.switcher-noop-none {
        .ant-tree-switcher-noop {
            display: none;
        }
    }

    .ant-tree-treenode {
        padding: 6px 0 10px;

        &::before {
            border-radius: 6px;
        }
    }
}

// 农事详情 日常汇报 查看图片 添加的附加信息样式
.ant-image-preview-tooltip {
    width: 100vw;
    height: 46px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1090;
    color: white;
    text-align: center;
    line-height: 46px;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: flex;
    justify-content: center;

    >div {
        margin: 0 10px;
    }
}


.not-right-border {
    .ant-select-selector {
        border-right: none !important;
    }
}

.ant-picker-cell-disabled::before{
    background-color: transparent !important;
}

.color-popover {
    .ant-popover-inner {
        padding: 0 !important;
    }
}

.color-block {
    width: 30px;
    height: 18px;
    border: 1px solid #ededed;
    border-radius: 2px;
    padding: 2px;
    cursor: pointer; 
    .block {
        width: 24px;
        height: 12px;
        border-radius: 2px;
        background-color: var(--color);
    }
}
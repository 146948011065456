.layout {
    width: 100vw;
    height: 100vh;

    background-image: url('./image/login.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    // background-image: url('./image/bg.svg');
    .ant-typography {
        text-align: center;
    }

    .ant-layout-content {
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .login-form {
        width: 16vw;
        min-width: 380px;
        max-width: 520px;
        box-shadow: #82ccb0 0px 0px 40px;
        position: absolute;
        right: 8vw;
        top: 20vh;
        padding: 30px;
        border-radius: 20px;
        // background-color: #7BC0B2;
        background-color: #fff;
    }

    .login-form-button {
        width: 100%;
    }

    .ant-input-affix-wrapper {
        padding: 11px;
    }


    .waves {
        position: relative;
        width: 100%;
        height: 15vh;
        margin-bottom: -7px;
        /*Fix for safari gap*/
        min-height: 100px;
        max-height: 150px;
    }

    /* Animation */

    .parallax>use {
        animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
    }

    .parallax>use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
    }

    .parallax>use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
    }

    .parallax>use:nth-child(3) {
        animation-delay: -4s;
        animation-duration: 13s;
    }

    .parallax>use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
    }

    @keyframes move-forever {
        0% {
            transform: translate3d(-90px, 0, 0);
        }

        100% {
            transform: translate3d(85px, 0, 0);
        }
    }

    /*Shrinking for mobile*/
    @media (max-width: 768px) {
        .waves {
            height: 40px;
            min-height: 40px;
        }

        .content {
            height: 30vh;
        }

        h1 {
            font-size: 24px;
        }
    }
}
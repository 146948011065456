div[class^='layout'],
div[class^='theme'] {
    position: relative;
    width: 156px;
    height: 100px;
    overflow: hidden;
    border-radius: var(--borderRadius);
    box-shadow: 0 1px 2.5px 0 rgb(0 0 0 / 18%);
    cursor: pointer;

    &::before {
        content: '';
        width: 33%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &::after {
        content: '';
        width: 100%;
        height: 25%;
        position: absolute;
        top: 0;
        left: 0;
    }

    // 侧边菜单布局
    &.layout-sider {
        background-color: #FFFFFF;

        &::before {
            background-color: #000;
            z-index: 999;
        }

        &::after {
            background-color: #f5f5f5;
        }
    }

    //顶部菜单布局
    &.layout-top {
        background-color: #FFFFFF;

        &::before {
            background-color: #f5f5f5;
        }

        &::after {
            background-color: #000;
        }
    }

    //混合菜单布局
    &.layout-blend {
        background-color: #FFFFFF;

        &::before {
            background-color: #000;
        }

        &::after {
            background-color: #000;
        }
    }

    //浅色模式
    &.skin-light {
        background-color: #FFFFFF;

        &::before {
            background-color: #F0F2F5;
        }

        &::after {
            background-color: #F0F2F5;
        }
    }

    //深色模式
    &.skin-dark {
        background-color: #263849;

        &::before {
            background-color: #000;
        }

        &::after {
            background-color: #000;
        }
    }

    // 浅色模式 默认
    &.theme-light-default {
        background-color: #F5F5F5;

        &::before {
            background-color: #FFF;
        }

        &::after {
            background-color: #FFF;
        }
    }


    // 浅色模式 经典
    &.theme-light-classics {
        background-color: #F5F5F5;

        &::before {
            background-color: #001529;
        }

        &::after {
            background-color: #001529;
        }
    }

    // 浅色模式 浅灰（侧）
    &.theme-light-sidergray {
        background-color: #F5F5F5;

        &::before {
            background-color: #eaedf1;
        }

        &::after {
            background-color: #001529;
        }
    }


    // 深色模式 默认
    &.theme-dark-default {
        background-color: #263849;

        &::before {
            background-color: rgba(0, 21, 41, .65);
        }

        &::after {
            background-color: rgba(0, 21, 41, .85);
        }
    }
}

.color-block {
    width: 34px;
    height: 34px;
    background-color: var(--bgColor);
    border-radius: var(--borderRadius);
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}